import React from 'react'
import { Button, Drawer, Menu, MenuProps, Typography } from 'antd'
import { Link } from 'react-router'
import { BiLogOut, BiUserCircle } from 'react-icons/bi'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setOpen } from './slice'
import drawerStyles from './styles'
import SiderItems from '../sider/components/items'
import * as Constants from '../../constants'
import { setRequestedLogout } from '../../pages/login/slice'

export default function GToBDrawer() {
  const styles = drawerStyles()
  const { open } = useAppSelector((state) => state.drawer)
  const dispatch = useAppDispatch()

  const logout = () => {
    localStorage.removeItem(Constants.GTOB_TOKEN_IDENTIFIER)
    localStorage.removeItem(Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER)
    localStorage.removeItem(Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER)
    dispatch(setRequestedLogout(true))
  }

  const items: MenuProps['items'] = [
    {
      key: 'account',
      label: (
        <a href={Constants.GTOB_ACCOUNT_URL} target="_blank" rel="noreferrer">
          <Typography.Text style={styles.menuItemBottom}>Account</Typography.Text>
        </a>
      ),
      icon: <BiUserCircle />,
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: <Typography.Text style={styles.menuItemBottom}>Logout</Typography.Text>,
      onClick: logout,
      icon: <BiLogOut />,
    },
  ]

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={() => dispatch(setOpen(false))}
      contentWrapperStyle={styles.root}
      footer={<Menu items={items} />}
    >
      <Link to="/incident/create">
        <Button type="primary" block>
          Report Guest
        </Button>
      </Link>
      <SiderItems />
    </Drawer>
  )
}
