import React from 'react'
import { Layout as AntdLayout, theme, Grid, Affix } from 'antd'
import { Outlet } from 'react-router'
import Header from '../header'
import headerStyles from '../header/styles'
import Sider from '../sider'
import siderStyles from '../sider/styles'
import GToBDrawer from '../drawer'
import layoutStyles from './styles'
import { useAppSelector } from '../../app/hooks'
import KioskModeSider from '../sider/kiosk-mode-sider'

export default function Layout() {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = layoutStyles(!screens.md)
  const headerStyle = headerStyles(token, !screens.md)
  const siderStyle = siderStyles(token)
  const { Header: AntdHeader, Sider: AntdSider, Content } = AntdLayout
  const { kioskMode } = useAppSelector((state) => state.login)

  return (
    <AntdLayout style={styles.root}>
      <Affix>
        <AntdSider
          style={siderStyle.root as React.CSSProperties}
          breakpoint="md"
          trigger={null}
          collapsedWidth={0}
          width={250}
        >
          {kioskMode ? <KioskModeSider /> : <Sider />}
        </AntdSider>
      </Affix>

      <AntdLayout>
        {!kioskMode && (
          <AntdHeader style={headerStyle.root as React.CSSProperties}>
            <Header />
          </AntdHeader>
        )}
        <AntdLayout>
          <GToBDrawer />
          <Content style={styles.container}>
            <Outlet />
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  )
}
