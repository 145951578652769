import { ErrorT } from '../../../../../../../types/errorT'

const buildSupportEmail = (error: ErrorT) => {
  const subject = `Error when creating a report - ${error.message}`
  const body = `Technical Details: ${error.error.map(
    (technicalError) => `${technicalError.message}`
  )}
  `
  return `mailto:api-support@goodtobook.com?subject=${subject}&body=${body}`
}

export default buildSupportEmail
