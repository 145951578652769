/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router'
import { Image, Space, Spin, theme, Typography } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import getAccessToken from './actions'
import StatusE from '../../types/statusE'
import { setIsLoggedIn, setNextURL } from './slice'
import loginStyles from './styles'
import logo from '../../assets/images/logo.svg'
import { GTOB_TOKEN_IDENTIFIER, GTOB_URL, LOGIN_SCOPES } from '../../constants'

export default function Login() {
  const { token: cssToken } = theme.useToken()
  const styles = loginStyles(cssToken)
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { status, error, isLoggedIn, requestedLogout } = useAppSelector((state) => state.login)
  const navigate = useNavigate()
  const token = localStorage.getItem(GTOB_TOKEN_IDENTIFIER)
  const authorizationCode = searchParams.get('code')
  const nextState = searchParams.get('state')
  const location = useLocation()

  useEffect(() => {
    if (token) {
      dispatch(setIsLoggedIn(true))
    } else if (requestedLogout) {
      window.location.replace(
        `https://login.goodtobook.com/oauth/destroy?post_logout_redirect_uri=${GTOB_URL}`
      )
    } else if (!authorizationCode) {
      const state = uuidv4()
      if (location.state) {
        localStorage.setItem(state, (location.state ?? '').toString())
      }
      window.location.replace(
        `${process.env.REACT_APP_API_URL}/oauth/authorize?client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&response_type=code&scope=${LOGIN_SCOPES}&state=${state}`
      )
    }
  }, [token, authorizationCode, requestedLogout, location.state])

  useEffect(() => {
    if (nextState) {
      const nextStateURL = localStorage.getItem(nextState)
      if (nextStateURL) {
        dispatch(setNextURL(nextStateURL))
      }
    }
  }, [nextState])

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn, navigate])

  useEffect(() => {
    if (authorizationCode) {
      dispatch(getAccessToken(authorizationCode))
    }
  }, [authorizationCode])

  return (
    <Space style={styles.root}>
      <Space style={styles.container as React.CSSProperties}>
        <Image src={logo} preview={false} />
        <Spin spinning={status === StatusE.LOADING} size="large" />
        {error && <Typography.Text type="secondary">{error.error_description}</Typography.Text>}
      </Space>
    </Space>
  )
}
