import { Flex, Typography } from 'antd'
import React from 'react'
import { ErrorT } from '../../../../../../../types/errorT'
import buildSupportEmail from './ErrorNotification.utils'

export default function ErrorNotification({ error }: { error: ErrorT }) {
  const emailHref = buildSupportEmail(error)

  return (
    <Flex vertical>
      <Typography>
        {error.message}
        {'. '}
        {error.error.map((technicalError) => (
          <Typography.Text type="secondary" key={technicalError.message}>
            {technicalError.message}
          </Typography.Text>
        ))}
      </Typography>
      <br />
      <Typography.Paragraph type="secondary">
        If the error persists, please <a href={emailHref}>contact</a> our support team.
      </Typography.Paragraph>
    </Flex>
  )
}
