import React from 'react'
import { createBrowserRouter } from 'react-router'
import Login from '../pages/login'
import Layout from '../components/layout'
import CreateIncident from '../pages/incident/create'
import IncidentDetail from '../pages/incident/detail'
import GuestCheck from '../pages/guest/check'
import Account from '../pages/account'
import Authenticator from '../components/authenticator'

export const router = createBrowserRouter([
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '/',
    element: (
      <Authenticator>
        <Layout />
      </Authenticator>
    ),
    children: [
      {
        index: true, // Marks this as the default child route
        element: (
          <Authenticator>
            <GuestCheck />
          </Authenticator>
        ),
      },
      {
        path: 'incident',
        children: [
          {
            path: 'create',
            element: (
              <Authenticator>
                <CreateIncident />
              </Authenticator>
            ),
          },
          {
            path: ':incidentId',
            element: (
              <Authenticator>
                <IncidentDetail />
              </Authenticator>
            ),
          },
        ],
      },
      {
        path: 'account',
        element: (
          <Authenticator>
            <Account />
          </Authenticator>
        ),
      },
    ],
  },
])

export default router
