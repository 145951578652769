import { Breakpoint, GlobalToken } from 'antd'

const styles = (theme: GlobalToken, screens: Partial<Record<Breakpoint, boolean>>) => ({
  cardContainer: {
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colorBorderSecondary,
  },
  cardBodyRow: {
    margin: 16,
    gap: !screens.md ? 8 : 16,
  },
  cardBodyColumn: {
    textAlign: 'end',
  },
  personContainer: {
    padding: 18,
  },
  personContainerItem: {
    padding: 0,
    width: '100%',
  },
  personContainerImages: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: !screens.md ? 16 : 0,
    alignItems: 'center',
  },
  tagPersonType: {
    backgroundColor: theme.colorBgSpotlight,
    color: theme.colorWhite,
    padding: '4px 8px',
    fontSize: theme.fontSizeLG,
  },
  personImage: { maxHeight: 230, maxWidth: 250 },
})

export default styles
