import React from 'react'
import { RouterProvider } from 'react-router'
import { ConfigProvider } from 'antd'
import { router } from './app/router'
import theme from './theme'

export default function App() {
  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  )
}
