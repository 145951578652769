import React from 'react'
import { Button, Col, Image, Row, theme } from 'antd'
import { Link, useNavigate } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import { AiOutlineMenu } from 'react-icons/ai'
import headerStyles from './styles'
import logo from '../../assets/images/logo.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setOpen } from '../drawer/slice'
import { setSelectedItem } from '../sider/slice'

export default function Header() {
  const { token } = theme.useToken()
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })
  const styles = headerStyles(token, isTabletOrMobile)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { open } = useAppSelector((state) => state.drawer)

  const onHandleReportGuest = () => {
    dispatch(setSelectedItem('report_incident'))
    navigate('/incident/create')
  }

  return (
    <Row>
      <Col span={24} style={styles.container}>
        <Link to="/" style={styles.logoContainer}>
          <Image src={logo} style={styles.logo} preview={false} />
        </Link>
        {isTabletOrMobile ? (
          <Button
            type="primary"
            icon={<AiOutlineMenu style={styles.iconButton} />}
            onClick={() => dispatch(setOpen(!open))}
          />
        ) : (
          <Row gutter={12} style={{ alignItems: 'center' }}>
            <Col>
              <Button type="primary" onClick={onHandleReportGuest}>
                Report Guest Incident
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
