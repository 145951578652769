import React from 'react'
import { Col, Image, Row, theme } from 'antd'
import { Link } from 'react-router'
import siderStyles from './styles'
import logo from '../../assets/images/logo.svg'
import SiderItems from './components/items'

export default function Sider() {
  const { token } = theme.useToken()
  const styles = siderStyles(token)

  const logoImage = <Image src={logo} style={{ ...styles.logo, width: 200 }} preview={false} />

  return (
    <Row>
      <Col>
        <Link to="/" style={styles.logoContainer}>
          {logoImage}
        </Link>
        <SiderItems />
      </Col>
    </Row>
  )
}
