import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Avatar, Col, Grid, Row, Skeleton, Tag, Typography, theme } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { getIncidentDetail } from '../actions'
import getStyles from './styles'
import { formatDate } from '../../../utils'
import IncidentDetailPeople from './components/people'
import StatusE from '../../../types/statusE'
import { DATE_TIME_FORMAT, INPUT_DATA_OFFENCES } from '../../../constants'
import IncidentDetailVehicle from './components/vehicles'

export default function IncidentDetail() {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = getStyles(token, screens)
  const { incidentId } = useParams<{ incidentId: string }>()
  const dispatch = useAppDispatch()
  const { incidentDetail, status } = useAppSelector((state) => state.incident)

  useEffect(() => {
    if (incidentId) {
      dispatch(getIncidentDetail(incidentId))
    }
  }, [incidentId]) // eslint-disable-line react-hooks/exhaustive-deps

  const getOffenceLabel = (offence: string) =>
    INPUT_DATA_OFFENCES.find((item) => item.value === offence)
      ?.label?.toString()
      .toUpperCase()

  return (
    <Row style={styles.root}>
      <Col xs={20} style={styles.container as React.CSSProperties}>
        <Skeleton active loading={status === StatusE.LOADING}>
          <Row style={styles.cardContainer}>
            <Row style={styles.cardHeaderContainer}>
              <Typography.Title level={5} style={styles.cardHeadertitle}>
                Guest Incident Report
              </Typography.Title>
            </Row>
            <Row style={styles.cardBodyContainer}>
              <Col span={24}>
                <Row style={styles.cardBodyRow}>
                  <Col xs={8} lg={5} xl={4} style={styles.cardBodyColumn as React.CSSProperties}>
                    <Typography.Text type="secondary">INCIDENT DATE</Typography.Text>
                  </Col>
                  <Col push={screens.xs ? 0 : 1}>
                    <Typography.Text>
                      {incidentDetail?.datetime &&
                        formatDate(incidentDetail?.datetime, DATE_TIME_FORMAT)}
                    </Typography.Text>
                  </Col>
                </Row>
                {incidentDetail?.offences && incidentDetail?.offences.length > 0 && (
                  <Row style={styles.cardBodyRow}>
                    <Col xs={8} lg={5} xl={4} style={styles.cardBodyColumn as React.CSSProperties}>
                      <Typography.Text type="secondary">OFFENCES</Typography.Text>
                    </Col>
                    <Col push={screens.xs ? 0 : 1}>
                      {incidentDetail?.offences.map((offence) => (
                        <Tag key={offence}>{getOffenceLabel(offence)}</Tag>
                      ))}
                    </Col>
                  </Row>
                )}
                {incidentDetail?.description && (
                  <Row style={styles.cardBodyRow}>
                    <Col lg={5} xl={4} style={styles.cardBodyColumn as React.CSSProperties}>
                      <Typography.Text type="secondary">INCIDENT DESCRIPTION</Typography.Text>
                    </Col>
                    <Col push={screens.xs ? 0 : 1}>
                      <Typography.Text>{incidentDetail?.description}</Typography.Text>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Row>
        </Skeleton>
        <Skeleton active loading={status === StatusE.LOADING}>
          {incidentDetail?.people && (
            <Row>
              <Row style={styles.cardHeaderSecondaryContainer}>
                <Typography.Title level={5} style={styles.cardHeaderSecondarytitle}>
                  People
                </Typography.Title>
                <Avatar shape="square" size="small" style={styles.cardHeaderSecondarytitleBadge}>
                  {incidentDetail?.people.length}
                </Avatar>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col span={24}>
                  <IncidentDetailPeople people={incidentDetail?.people} />
                </Col>
              </Row>
            </Row>
          )}
        </Skeleton>
        <Skeleton active loading={status === StatusE.LOADING}>
          {incidentDetail?.vehicles ? (
            <Row>
              <Row style={styles.cardHeaderSecondaryContainer}>
                <Typography.Title level={5} style={styles.cardHeaderSecondarytitle}>
                  Vehicles
                </Typography.Title>
                <Avatar shape="square" size="small" style={styles.cardHeaderSecondarytitleBadge}>
                  {incidentDetail?.vehicles.length}
                </Avatar>
              </Row>
              <Row style={{ width: '100%' }}>
                {incidentDetail?.vehicles ? (
                  <Col span={24}>
                    <IncidentDetailVehicle vehicles={incidentDetail?.vehicles} />
                  </Col>
                ) : null}
              </Row>
            </Row>
          ) : null}
        </Skeleton>
      </Col>
    </Row>
  )
}
