import React from 'react'
import { Col, Grid, Menu, MenuProps, Row, Typography } from 'antd'
import { Link } from 'react-router'
import { BiUserCircle, BiLogOut } from 'react-icons/bi'
import { FiExternalLink } from 'react-icons/fi'
import siderItemStyles from './styles'
import * as Constants from '../../../../constants'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { setRequestedLogout } from '../../../../pages/login/slice'
import { setSelectedItem } from '../../slice'
import { setOpen } from '../../../drawer/slice'

export default function SiderItems() {
  const screens = Grid.useBreakpoint()
  const styles = siderItemStyles(!screens.md)
  const dispatch = useAppDispatch()
  const { selectedItem } = useAppSelector((state) => state.sider)

  const logout = async () => {
    localStorage.removeItem(Constants.GTOB_TOKEN_IDENTIFIER)
    localStorage.removeItem(Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER)
    localStorage.removeItem(Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER)
    dispatch(setRequestedLogout(true))
  }

  const onHandleSelectedKey = (key: string) => dispatch(setSelectedItem(key))

  const items: MenuProps['items'] = [
    {
      key: 'check_guest',
      label: (
        <Link to="/">
          <Typography.Title level={5} style={styles.menuItem}>
            Check Guest
          </Typography.Title>
        </Link>
      ),
      onClick: (info) => {
        onHandleSelectedKey(info.key)
        dispatch(setOpen(false))
      },
    },
    {
      key: 'report_incident',
      label: (
        <Link to="/incident/create">
          <Typography.Title level={5} style={styles.menuItem}>
            Report Incident
          </Typography.Title>
        </Link>
      ),
      onClick: (info) => {
        onHandleSelectedKey(info.key)
        dispatch(setOpen(false))
      },
    },
    {
      key: 'member_resources',
      label: (
        <a href={Constants.GTOB_RESOURCES_URL} target="_blank" rel="noreferrer">
          <Row style={styles.menuIconItem}>
            <Typography.Title level={5} style={styles.menuItem}>
              Member Resources
            </Typography.Title>
            <FiExternalLink />
          </Row>
        </a>
      ),
    },
  ]

  const accountItems: MenuProps['items'] = [
    {
      key: 'account',
      label: (
        <Link to="/account">
          <Typography.Text style={styles.menuItemBottom}>Account</Typography.Text>
        </Link>
      ),
      icon: <BiUserCircle />,
      onClick: (info) => {
        onHandleSelectedKey(info.key)
        dispatch(setOpen(false))
      },
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: <Typography.Text style={styles.menuItemBottom}>Logout</Typography.Text>,
      onClick: logout,
      icon: <BiLogOut />,
    },
  ]

  return (
    <Row>
      <Col style={styles.root as React.CSSProperties}>
        <Row>
          <Menu style={styles.menuContainer} items={items} selectedKeys={[selectedItem]} />
        </Row>
        <Row style={styles.menuDropdownContainer as React.CSSProperties}>
          <Menu style={styles.menuContainer} items={accountItems} selectedKeys={[selectedItem]} />
        </Row>
      </Col>
    </Row>
  )
}
